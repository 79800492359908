<template>
  <template v-for="item in items" :key="item">
    <van-card
      :desc="item.subtitle"
      :title="item.title"
      :thumb="item.pic"
    >
      <template #footer>
        <van-button size="small" :type="item.selected ? 'primary' : 'default'" @click="onClick(item)">{{ item.selected ? '已选择' : '选择' }}</van-button>
      </template>
    </van-card>
  </template>
  <div style="margin: 16px;">
    <van-button type="primary" block @click="onOk">选好了</van-button>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  emits: ['select'],
  props: {
  },
  setup (props, { emit }) {
    const post = inject('post')
    const state = reactive({
      items: [],
      selects: [2]
    })
    /*
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    */
    const onOk = () => {
      const rst = state.items.filter((value, key, arr) => {
        return value.selected
      })
      console.log(rst)
    }
    const onClick = (row) => {
      row.selected = !row.selected
    }
    const onClick2 = (row) => {
      emit('select', row)
    }
    const init = () => {
      post('/staff.list', {
        storeId: state.storeId
      }).then(res => {
        for (const v of res.data.content) {
          state.items.push({
            id: v.staffId,
            title: v.name,
            subtitle: v.user.phone,
            pic: v.avatar,
            selected: state.selects.indexOf(v.staffId) > -1
          })
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClick2,
      onClick,
      onOk
    }
  }
}
</script>

<style scoped>
body {
  background:#ff6600;
}
</style>
